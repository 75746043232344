import { config } from '@/utils'

export default {
    name: 'Retrieval',

    computed: {
        categoryItem() {

            let arr = this.categorys[this.categoryIndex] || {};
            return arr;
        },
        categorySubItems() {
            let items = this.categorys;
            let index = this.categoryIndex;
            return (items[index] && items[index].category_sub) || []
        },
        categoryThirdItems() {
            let index = this.categorySubIndex;
            if (index == '-1') return []
            return (this.categorySubItems[index] && this.categorySubItems[index].category_sub) || [];
        }
    },

    mounted() {
        this.handleGetCategoryList(); // 获取项目类别列表
        this.handleStaffProperty(); // 获取劳务关系
        this.template();    //获取内部员工
        // this.getMaterialList()
    },
    methods: {
        // 清空选择框
        clearSelect(e, i) {
            this.$refs[e].clearSingleSelect();
            if (i == '-1') {
                if (e == 'resetSubSelect') {//第一级选择全部
                    this.categorySubIndex = '-1';
                }
                this.categoryThirdIndex = '-1';
            }
            this.clearChange();

        },
        clearChange(){
            this.isModal = false;
            this.date = [];
            this.archives_name = '';
            this.party_a = '';
            this.party_b = '';
            this.third_party = '';
            this.handlers = '';
            this.name = '';
            this.gander = 3;
            this.company_name = '';
            this.archives_code = '';
            this.tmp_number = '';
            this.finance_number = '';
            this.identity_card = '';
            this.birthday = '';
            this.items = [];
            this.page = 1;
            this.obj = {};
            this.staff_property = '';

            this.education='',
            this.staff_type='',
            this.employing_unit='',
            this.employer='',

            this.project_name='';
            this.dingtalk_number='';
            this.contract_number='';
            this.contract_amount_begin='';
            this.contract_amount_end='';

            this.checkValue = [];//资料清单
        },
        // 监听第三级分类回调
        changes(e) {
            if (!this.categoryThirdIndex || this.categoryThirdIndex.length == 0) return
            let st = this.categoryThirdIndex.indexOf('-1') > -1;
            if (st) this.categoryThirdIndex = ['-1']
            this.checkMultiple = !st;
        },
        // 获取项目类别列表
        handleGetCategoryList() {
            if (this.loading) return;
            this.loading = true
            this.$api.archives.categoryList({category_tag:'archive_search',permission_type:2}).then(res => {
                this.loading = false
                if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg })
                this.categorys = res.list
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },

        // 获取资料清单
        getMaterialList(){
            this.resourceLabel = [];
            this.$api.archives.getMaterialList({
                archive_type: this.categoryItem.category_key
            }).then(res=> {
                this.resourceLabel=[]
                for(var i in res.list) {this.resourceLabel.push(res.list[i])}
            }).catch(err=>{


            })
        },
        // 获取资料清单
        getExtendOptionList(){
            this.extendOptionList = [];
            this.$api.archives.getExtendOptionList_for_search({
                category_key: this.categoryItem.category_key,
                info_key:'material_list'
            }).then(res=> {
                this.extendOptionList=[]
                for(var i in res.list) {this.extendOptionList.push(res.list[i])}
            }).catch(err=>{
            })
        },

        // 获取劳务关系
        handleStaffProperty() {
            this.$api.archives.staffProperty().then(res => {
                if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg })
                this.staff_property_items = res.list;
            }).catch(err => {
                console.log(err)
            })
        },
        // 获取劳务关系
        template() {
            this.$api.archives.template({
                archive_type:'employee_file'
            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg })
                this.template_list = res.obj.staff_type;
                this.education_list=res.obj.education
            }).catch(err => {
                console.log(err)
            })
        },

        onSelectChange(e) {
            this.exportList = e;
        },

        onSelect(list,obj) {

        },
        setDate(e){
            this.birthday=e;
        },
        setStartTime(e){
            this.date=e;
        },

        // 检索
        handleSearch() {
            if (this.loading) return;

            if (this.identity_card != '' && !/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.identity_card)) return this.$Message.warning({ content: '身份证格式不正确'});
            this.$Message.loading({ content: '数据请求中...', duration: 0 })
            this.loading = true
            let options = {
                search_type: this.categoryIndex == '-1' ? '' : this.categoryItem.category_key,
                second_category_id: this.categorySubIndex == '-1' ? 0 : (typeof this.categorySubIndex != 'number') ? '' : this.categoryItem.category_sub[this.categorySubIndex].category_id,
                third_category_ids: this.categoryThirdIndex == '-1' ? [] : this.categoryThirdIndex,
                begin_date: this.date[0],
                end_date: this.date[1],
                company_name: this.company_name,
                archives_name: this.archives_name,
                party_a: this.party_a,
                party_b: this.party_b,
                third_party:this.third_party,
                staff_property: this.staff_property,
                handlers: this.handlers,
                name: this.name,
                gander: this.gander,
                archives_code: this.archives_code,
                tmp_number: this.tmp_number,
                finance_number:this.finance_number,
                identity_card: this.identity_card,
                birthday: this.birthday,
                page: this.page,
                page_number: 10,
                material_list:this.checkValue,

                education:this.education,
                staff_type:this.staff_type,
                employing_unit:this.employing_unit,
                contract_amount_begin:this.contract_amount_begin,
                contract_amount_end:this.contract_amount_end,
                contract_number:this.contract_number,
                dingtalk_number:this.dingtalk_number,
                project_name:this.project_name,
                employer:this.employer
            };
            if (this.categoryItem.category_key == 'partner') {
                options = {
                    search_type: "partner",
                    company_name: this.company_name,
                    archives_code: this.archives_code,
                    finance_number:this.finance_number,
                    handlers: this.handlers,
                    page: this.page,
                    page_number: 10,
                    material_list:this.checkValue
                }
            }
            this.$api.archives.archivesSearch(options).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg })
                this.items = res.list;
                this.obj = res.obj;
                this.isModal = true
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        },

        // 导出接口
        handleExport() {
           let arr = [];
           if(this.exportList.length>0) {
                arr = JSON.stringify( this.exportList.map(v=>{
                    return v.archive_id||v.archives_id
               }));
           }
            // this.$refs.selection.selectAll(false);//清空选择
            let url = '', params = '',material_list = JSON.stringify(this.checkValue);
            params = `${config.apiurl}/archives/export?second_category_id=${this.categorySubIndex == '-1' ? '0' : (typeof this.categorySubIndex != 'number') ? '' : this.categoryItem.category_sub[this.categorySubIndex].category_id}&gander=${this.gander}&search_type=${this.categoryItem.category_key}`;
            // 员工档案
            if (this.categoryItem.category_key == 'employee_file'||this.categoryItem.category_key == 'expatriates') {
                url += `${params}&token=${_global.token}&material_list=${material_list}&`;
                if (this.date.length > 0) url += `begin_date=${this.date[0]}&end_date=${this.date[1]}&`;
                if (arr.length>0) url += `archives_ids=${arr}&`;
                if (this.staff_property) url += `staff_property=${this.staff_property}&`;
                if (this.name) url += `name=${this.name}&`;
                if (this.identity_card) url += `identity_card=${this.identity_card}&`;
                if (this.archives_code) url += `archives_code=${this.archives_code}&`;
                if (this.birthday) url += `birthday=${this.birthday}`;

                if (this.education) url += `education=${this.education}`;
                if (this.staff_type) url += `staff_type=${this.staff_type}`;
                if (this.staff_property) url += `staff_property=${this.staff_property}`;
                if (this.employing_unit) url += `birthday=${this.employing_unit}`;
                if(this.categoryItem.category_key == 'expatriates'){
                    if (this.employer) url += `employer=${this.employer}`;
                }
            }
            // 商务
            else if (this.categoryItem.category_key == 'business_contract') {
                url += `${params}&token=${_global.token}&material_list=${material_list}&`;
                if (this.date.length > 0) url += `begin_date=${this.date[0]}&end_date=${this.date[1]}&`;
                if (this.archives_name) url += `archives_name=${this.archives_name}&`;
                if (arr.length>0) url += `archives_ids=${arr}&`;
                if (this.archives_code) url += `archives_code=${this.archives_code}&`;
                if (this.party_a) url += `party_a=${this.party_a}&`;
                if (this.party_b) url += `party_b=${this.party_b}&`;
                if (this.handlers) url += `handlers=${this.handlers}`;

                if (this.project_name) url += `project_name=${this.project_name}`;
                if (this.dingtalk_number) url += `dingtalk_number=${this.dingtalk_number}`;
                if (this.contract_number) url += `handcontract_numberlers=${this.contract_number}`;
                if (this.contract_amount_begin) url += `contract_amount_begin=${this.contract_amount_begin}`;
                if (this.contract_amount_end) url += `contract_amount_end=${this.contract_amount_end}`;
            } else if(this.categoryItem.category_key == 'partner') {
                url += `${config.apiurl}/archives/export?search_type=${this.categoryItem.category_key}&company_name=${this.company_name}&token=${_global.token}&material_list=${material_list}&`
                if (arr.length>0) url += `archives_ids=${arr}`;
                if (this.handlers) url += `handlers=${this.handlers}`;
                if (this.archives_code) url += `archives_code=${this.archives_code}`;
            }
            if(this.categoryItem.category_key==undefined){
                url += `${config.apiurl}/archives/export?search_type=${this.categoryItem.category_key}&company_name=${this.company_name}&token=${_global.token}&material_list=${material_list}&`
                if (arr.length>0) url += `archives_ids=${arr}`;
            }
            // /~api/archives/export?second_category_id=&gander=3&search_type=business_contract&token=ed5a20548da59a8d6cc289d39cb18942.612f3b0ba9dd6414536202&material_list=[]&begin_date=&end_date=&archives_name=阳山物业&
            window.location.href = url;

        },

        // 跳转详情
        handleRowClick(row) {
            // this.$router.push({ name: 'RetrievalDetail', query: { type: row.archive_type, code: row.archives_code } })
            const newTag = this.$router.resolve({name: 'RetrievalDetail', query: { type: row.archive_type, code: row.archives_code }})
            window.open(newTag.href,'_blank');
        },

        // 关闭
        handleClose() {
            this.education='',
            this.staff_type='',
            this.staff_property='',
            this.employing_unit='',
            this.employer='',


            this.isModal = false;
            this.date = [];
            this.archives_name = '';
            this.party_a = '';
            this.party_b = '';
            this.third_party = '';
            this.handlers = '';
            this.name = '';
            this.gander = 3;
            this.company_name = '';
            this.archives_code = '';
            this.tmp_number = '';
            this.finance_number = '';
            this.identity_card = '';
            this.birthday = '';
            this.items = [];
            this.page = 1;
            this.obj = {};
            this.staff_property = '';

            this.project_name='';
            this.dingtalk_number='';
            this.contract_number='';
            this.contract_amount_begin='';
            this.contract_amount_end='';

            this.checkValue = [];//资料清单
            // this.categoryIndex = '-1';
            this.categorySubIndex = '-1';
            this.categoryThirdIndex =['-1'];
        }
    },
    inject: ["reload"],
    data() {
        return {
            project_name:'',
            dingtalk_number:'',
            contract_number:'',
            employer:'',


            contract_amount_begin:'',
            contract_amount_end:'',
            education_list:[],
            education:'',
            loading: false,
            keyword: '',

            // 项目类别
            categoryIndex: '-1',
            categorySubIndex: '-1',
            categoryThirdIndex: ['-1'],

            categorys: [],
            staff_property: '',  // 劳动关系
            staff_property_items: [],  // 劳务关系数组
            staff_type:'',   //员工类型
            employing_unit:'',//用工单位
            template_list:[],
            checkMultiple: true,
            date: [],
            archives_name: '',  // 档案名称
            party_a: '',        // 甲方
            party_b: '',        // 乙方
            third_party:'',     // 丙方
            handlers: '',       // 经手人
            name: '',           // 员工姓名
            gander: 3,          // 性别：0-全部，1-男，2-女
            archives_code: '',  // 档案编号
            tmp_number: '', //编号
            finance_number:'', //财务编号
            identity_card: '',  // 身份证
            birthday: '',       // 生日，格式：20190208
            resourceLabel: [],//资料清单列表
            extendOptionList:[], //自定义资料清单列表
            checkValue: [],//资料清单选中的值
            company_name: '',//企业名称

            exportList: [],//要导出的数据

            page: 1,
            items: [],
            obj: {},
            isModal: false,
            // 商务协议
            columns: [
                {
                    type: 'selection',
                    width: 60,
                    title: '全选',
                    align: 'center'
                },
                {
                    title: '年份',
                    key: 'archives_date',
                    align: 'center',
                    width: 80
                },
                {
                    title: '文件编号',
                    key: 'archives_code',
                    width: 140
                },
                {
                    title: '合同名称',
                    slot: 'archives_name',
                    minWidth: 160
                },
                {
                    title: '项目名称',
                    slot: 'project_name',
                    minWidth: 160
                },
                {
                    title: '甲方',
                    key: 'party_a',
                    width: 160
                },
                {
                    title: '乙方',
                    key: 'party_b',
                    width: 160
                },
                {
                    title: '合同金额(元)',
                    key: 'contract_amount',
                    width: 120
                },
                {
                    title: '文件目录',
                    key: 'file_names',
                    align: 'center',
                    slot: 'file_names',
                    width: 200
                },
                {
                    title: '经办人',
                    key: 'handlers',
                    width: 180
                }
            ],

            columns_staff: [
                {
                    type: 'selection',
                    width: 60,
                    title: '全选',
                    align: 'center'
                },
                {
                    title: '年份',
                    key: 'archives_date',
                    align: 'center',
                    width: 120
                },
                {
                    title: '文件编号',
                    key: 'archives_code',
                    width: 140
                },
                {
                    title: '员工姓名',
                    key: 'file_name',
                    width: 100
                },
                {
                    title: '性别',
                    key: 'gander',
                    align: 'center',
                    // width: 100
                },
                {
                    title: '学历',
                    key: 'education',
                    align: 'center',
                    width: 100
                },
                {
                    title: '生日',
                    key: 'birthday',
                    align: 'center',
                    width: 120
                },
                {
                    title: '员工类型',
                    key: 'staff_type',
                    align: 'center',
                    width: 120
                },
                {
                    title: '员工状态',
                    key: 'staff_property',
                    align: 'center',
                    width: 120
                },
                // {
                //     title: '劳动关系',
                //     key: 'staff_property',
                //     align: 'center',
                //     width: 120
                // },
                {
                    title: '服务公司',
                    key: 'employing_unit',
                    slot: 'employing_unit',
                    width: 160
                },
                {
                    title: '签约公司',
                    key: 'employer',
                    slot: 'employer',
                    width: 160
                },
                {
                    title: '文件目录',
                    key: 'file_names',
                    align: 'center',
                    slot: 'file_names',
                    width: 200
                },
                {
                    title: '经办人',
                    key: 'handlers',
                    align: 'center',
                    width: 120,
                }
            ],
            columns_compony: [
                {
                    type: 'selection',
                    width: 60,
                    title: '全选',
                    align: 'center'
                },
                {
                    title: '年份',
                    key: 'archives_date',
                    align: 'center',
                    width: 120
                },
                {
                    title: '文件编号',
                    key: 'archives_code',
                    width: 200
                },
                {
                    title: '文件名',
                    key: 'file_name',
                    align: 'center',
                    width: 260
                },
                {
                    title: '文件目录',
                    key: 'file_names',
                    slot: 'file_names',
                    align: 'center',
                    width: 150
                },
                {
                    title: '经办人',
                    key: 'handlers',
                    align: 'center',
                    width: 260
                }
            ],
            allType: [
                {
                    type: 'selection',
                    width: 60,
                    title: '全选',
                    align: 'center'
                },
                {
                    title: '年份',
                    key: 'archives_date',
                    align: 'center',
                    width: 120
                },
                {
                    title: '文件编号',
                    key: 'archives_code',
                    width: 140
                },
                {
                    title: '文档类型',
                    key: 'category_name',
                    width: 100
                },
                {
                    title: '项目类型',
                    key: 'third_category_name',
                    // slot: 'third_category_name',
                    width: 100
                },
                {
                    title: '项目名称',
                    key: 'project_name',
                    slot: 'project_name',
                    minWidth: 160
                },
                {
                    title: '服务公司',
                    key: 'employing_unit',
                    slot: 'employing_unit',
                    width: 160
                },
                {
                    title: '签约公司',
                    key: 'employer',
                    slot: 'employer',
                    width: 160
                },
                {
                    title: '员工姓名',
                    key: 'user_name',
                    slot: 'user_name',
                    width: 100
                },
                {
                    title: '文件名',
                    key: 'file_name',
                    slot: 'file_name',
                    width: 100
                },
                // {
                //     title: '性别',
                //     key: 'gander',
                //     align: 'center',
                //     width: 100
                // },
                // {
                //     title: '生日',
                //     key: 'birthday',
                //     align: 'center',
                //     width: 120
                // },
                // {
                //     title: '劳动关系',
                //     key: 'staff_property',
                //     align: 'center',
                //     width: 120
                // },
                // {
                //     title: '甲方',
                //     key: 'party_a',
                //     width: 100
                // },
                // {
                //     title: '乙方',
                //     key: 'party_b',
                //     width: 100
                // },
                {
                    title: '文件目录',
                    key: 'file_names',
                    align: 'center',
                    slot: 'file_names',
                    width: 200
                },
                {
                    title: '经办人',
                    key: 'handlers',
                    align: 'center',
                    width: 260
                }
            ],
            roleStaff:JSON.parse(localStorage.getItem('staff_auth_info')),
        }
    }
}